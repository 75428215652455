<template>
  <div id="nav">
      <router-link to="/">
       <img class="im" alt="ферма чораль" src="../assets/logo.jpg">
      </router-link>
      <router-link class="opa" to="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" fill="black" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </router-link>
      <div class="pro">
        <router-link to="/">Продукты</router-link>
      </div>
      <div class="shop">
        <router-link to="/cart">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi2 bi-cart3" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
          <span class="counter-cart" v-if="socketCart > 0"> {{ socketCart }} </span>
        </router-link>
      </div>
      <div class="phone">
        <a class="tel" href="tel:+79033592685">+7-903-359-26-85</a>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=79033592685"
        target="_blank"
        rel="noopener"
      >
        <img class='vk2' alt="" src="../assets/wal.png" />
      </a>
      <a class="btn_telegram_share" href="https://t.me/fermachoral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/telega.png"/>
      </a>
      <a href="https://vk.com/fermachoral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/vk.jpg"/>
      </a>
      <a href="https://dzen.ru/id/66025116b229214ee3732054" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/dzen.png"/>
      </a>
      <a href="https://bastyon.com/choral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/bastyon.png"/>
      </a>
      
    
  </div>
</template>

<script>
// import MenuView from "@/components/MenuView";

export default {
  // components: {
  //   MenuView,
  // },
  computed: {
    socketCart() {
      return this.$store.getters["quantity"];
    },
  },
};
</script>

<style scoped>
#nav {
  position: fixed;
  display: flex;
  align-items: center;  
  justify-content: center;
  height: auto;
  width: 100%;
  background-color: azure;
  /* background-image: url(./assets/head2.jpg); */
  /* background-repeat: repeat-x; */
}

.bi {
  display: none;
}

.d5 {
  position: absolute;
  left: 49vw;
  top: 3.3vh;
}

.pro, .shop, .phone {
  padding: 3vw;
}

a {
  font-size: 1.5vw;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
  text-decoration: none;
}

.counter-cart {
  display: table-caption;
  padding: 0.3vw 0.7vw 0.3vw 0.7vw;
  background-color: rgba(162, 205, 2, 1);
  color: white;
  border-radius: 50px;
  transition: all 0.4s ease;
}

.counter-cart:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.im {
  width: 15vw;
}

.vk {
  width: 3vw;
  transition: all 0.4s ease;
}
.vk2 {
  width: 3.5vw;
  transition: all 0.4s ease;
}

.vk:hover, .vk2:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.tel {
  font-size: 2vw;
  font-weight: bold;
  transition: all 0.4s ease;
}

.tel:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

@media (max-width: 767px) {

  .im {
    width: 200px;
  }

  #nav {
    justify-content: left;
  }

  .pro,
  .tel,
  .phone,
  .vk,
  .vk2 {
    display: none;
  }

  .shop {
    padding: 10vw;
  }

  .counter-cart {
    height: 25px;
    width: 30px;
    font-size: 25px;
  }

  .vk {
    width: 28px;
  }
  .vk2 {
    width: 33px;
  }

  .opa,
  .bi {
    display: inline;
    width: 50px;
    position: fixed;
    margin-top: -4.5%;
    left: 82%;
    z-index: 10;
  }

}

</style>