<template>
  <div class="foot">

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.foot {
    height: 40vh;
    background: gray;
    margin-top: 3vh;
}
</style>