import { createStore } from "vuex";

import rootMutations from "./mutations.js";
import rootActions from "./actions.js";
import rootGetters from "./getters.js";

const store = createStore({
  state() {
    return {
      cart: [],
      total: 0,
      qty: 0,
      products: [
        {
          id: 1,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Согум",
          // color: "Gold",
          // capacity: "128 GB",
          imgSrc: require("@/assets/sogum.jpg"),
          price: 3400,
        },
        {
          id: 2,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Говядина тушеная «Ферма Чораль» высшего сорта",
          // color: "Silver",
          // capacity: "128 GB",
          imgSrc: require("@/assets/tus.jpg"),
          price: 650,
        },
        {
          id: 3,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Траковский» от 4-х месяцев выдержки",
          // color: "Gold",
          // capacity: "128 GB",
          imgSrc: require("@/assets/tark4.jpg"),
          price: 3500,
        },
        {
          id: 4,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Траковский» от полутора месяцев выдержки",
          // color: "Graphite",
          // capacity: "128 GB",
          imgSrc: require("@/assets/tark15.jpg"),
          price: 2500,
        },
        {
          id: 5,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Сарчамар»",
          // color: "Silver",
          // capacity: "128 GB",
          imgSrc: require("@/assets/sarch.jpg"),
          price: 2300,
        },
        {
          id: 6,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Чораль» 1-2 месяца выдержки",
          // color: "Gold",
          // capacity: "128 GB",
          imgSrc: require("@/assets/choral.jpg"),
          price: 2100,
        },
        {
          id: 7,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Часлак»",
          // color: "Graphite",
          // capacity: "128 GB",
          imgSrc: require("@/assets/chaslak.jpg"),
          price: 1800,
        },
        {
          id: 8,
          // type: "Smartphone",
          // brand: "Apple",
          model: "Сыр «Сириклинский»",
          // color: "Silver",
          // capacity: "128 GB",
          imgSrc: require("@/assets/sirik.jpg"),
          price: 1600,
        },
       
      ],
    };
  },
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
});

export default store;
