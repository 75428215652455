<template>
  <div class="new">
    <div class="overlay"></div>
    <h1>Натуральные продукты</h1>
    <p>
      Ферма "Чораль" рада предложить вам сыры и мясную продукцию собственного производства
    </p>
    <div class="knop">
      <a
        href="https://api.whatsapp.com/send?phone=79033592685"
        target="_blank"
        rel="noopener"
      >
        <img class='vk2' alt="" src="../assets/wal.png" />
      </a>
      <a class="btn_telegram_share" href="https://t.me/fermachoral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/telega.png"/>
      </a>
      <a href="https://vk.com/fermachoral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/vk.jpg"/>
      </a>
      <a href="https://dzen.ru/id/66025116b229214ee3732054" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/dzen2.png"/>
      </a>
      <a href="https://bastyon.com/choral" target="_blank" rel="noopener">
        <img class='vk' alt="" src="../assets/bastyon.png"/>
      </a>
    </div>
    <div class="phone">
      <a class="tel" href="tel:+79033592685">+7 903 359 26 85</a>
    </div>
    <a href="https://choral.tilda.ws/">
      <button>Перейти к продуктам</button>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.new {
  height: 100vh;
  background-image: url("../assets/fon.jpg");
  background-size: cover;
  background-position-y: 75%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3); /* Настройка цвета и прозрачности для блока с заголовком */
  text-align: center;
}
h1 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 10%; /* Отступ сверху */
  font-size: 4.5vw; /* Размер текста заголовка */
  text-align: center;
  text-transform: uppercase; /* Заглавные буквы */
  color: white; /* Цвет заголовка */
  z-index: 1;
}
p {
  position: absolute;
  width: 100%;
  left: 0;
  top: 30%; /* Отступ сверху */
  font-size: 1.4vw; /* Размер текста заголовка */
  text-align: center;
  color: white; /* Цвет заголовка */
  z-index: 1;
}
.phone {
  position: absolute;
  left: 38%;
  top: 45%;
}
.tel {
  color: white;
  font-size: 3vw;
  text-decoration: none;
}
button {
  background-color: #ff623b;
  border: #ff623b;
  position: absolute;
  left: 40%;
  top: 77%;
  width: 20vw;
  height: 10vh;
  color: white;
  z-index: 1;
  font-size: 1.5vw;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.4s ease;
}
.knop {
  position: absolute;
  left: 42%;
  top: 59%;
  z-index: 1;
}
.vk {
  width: 3vw;
  transition: all 0.4s ease;
  z-index: 1;
}
.vk2 {
  width: 3.2vw;
  transition: all 0.4s ease;
  z-index: 1;
}

.vk:hover, .vk2:hover, button:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

@media (max-width: 767px) {

  .new {
    background-position-y: 75%;
    background-position-x: 50%;
  }
  h1 {
    font-size: 40px;
  }
  p {
    font-size: 20px;
  }
  button {
    width: 300px;
    height: 70px;
    font-size: 20px;
    left: 13.5%;
  }
  .phone {
    left: 12%;
    top: 48%;
  }
  .tel {
    font-size: 40px;
  }
  .knop {
    left: 7%;
    top: 61%;
    width: 87%;
    display: flex;
    justify-content: space-between;
  }
  .vk {
    width: 55px;
  }
  .vk2 {
    width: 57px;
  }
}

</style>